/* @jsx jsx */
import { jsx, Heading, Box, Grid, Text } from "theme-ui";
import { alpha, lighten } from "@theme-ui/color";
import { PageProps, graphql, Link } from "gatsby";
import { FaChevronLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ConfLogo from "../components/ConfLogo";
import Layout from "../components/Layout";
import HubspotForm from "../components/HubspotForm";
import ConfPass from "../components/ConfPass";
import Copy from "../components/Copy";

const containerStyles = {
  pt: `4`,
  pb: [`4`, `6`],
  px: [4, 5, 6, 0],
  alignItems: `center`,
  gridGap: [`3`, `4`, `5`],
  gridTemplateRows: `repeat(4, auto)`,
};

const headingStyles = {
  fontSize: [`6`, `7`],
  textTransform: `capitalize`,
  textAlign: `center`,
};

const minorHeaderStyles = {
  display: `flex`,
  justifyContent: `space-between`,
};

const HEADER_ITEM_WIDTH = 80;
const backLinkStyles = {
  width: HEADER_ITEM_WIDTH,
  fontSize: `2`,
  display: `flex`,
  alignItems: `center`,
  variant: `text.link`,
};

const emptyBoxStyles = {
  width: HEADER_ITEM_WIDTH, // making this the same size
};

const shareStyles = {
  fontSize: `3`,
};
const shareTextStyles = {
  textAlign: `center`,
};
const shareLinkStyles = {
  backgroundColor: alpha(`magenta`, 0.25),
  borderRadius: `3`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  pl: `3`,
};
const codeStyles = {
  fontFamily: `monospace`,
  overflowX: `auto`,
  color: lighten(`magenta`, 0.25),
};

const confPassWrapperStyles = {
  position: `relative`,
  display: `flex`,
  justifyContent: `center`,
};

const confPassOverlayStyles = {
  position: `absolute`,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background: (t) =>
    `linear-gradient(to bottom, ${t.colors.background}, transparent 5%)`,
};

const formNoteStyles = {
  fontSize: `3`,
  textAlign: `center`,
};
const homepageLinkStyles = {
  variant: `text.link`,
  color: `magenta`,
};

const RegistrantPage = ({ data }: PageProps<DataProps>) => {
  const {
    registrant: { firstName, lastName, number },
  } = data;
  const formattedFirstName =
    firstName?.charAt(0).toUpperCase() + firstName?.slice(1).toLowerCase();
  const formattedLastName =
    lastName?.charAt(0).toUpperCase() + lastName?.slice(1).toLowerCase();
  const name = `${formattedFirstName} ${formattedLastName}`;
  const bucketPath = encodeURIComponent(
    `${firstName}-${lastName}-${String(number).padStart(6, "0")}`.toUpperCase()
  );
  const ogImageUrl = `https://storage.googleapis.com/images.gatsbyconf.com/${bucketPath}/${bucketPath}.png`;
  const uniqueUrl = `https://gatsbyconf.com/conference-pass/${encodeURIComponent(
    `${firstName}-${lastName}`
  )}/`;

  return (
    <Layout>
      <Helmet>
        <title>{name}'s GatsbyConf Pass</title>
        <meta name="title" content={`${name}'s GatsbyConf Pass`} />
        <meta
          name="description"
          content={`Join ${name} at GatsbyConf online from March 2-3. A free event focused on the future web.`}
        />
        <meta name="image" content={ogImageUrl} />
        <meta itemProp="image" content={ogImageUrl} />
        <meta name="twitter:image" content={ogImageUrl} />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Grid sx={containerStyles} flexDirection="column">
        <Box sx={minorHeaderStyles}>
          <Link to="/" sx={backLinkStyles}>
            <FaChevronLeft /> Go back
          </Link>
          <Box>
            <ConfLogo />
          </Box>
          <Box sx={emptyBoxStyles} />
        </Box>
        <Heading sx={headingStyles}>
          <Text variant="gradient">{`${name}'s`}</Text>
          <Text>GatsbyConf Pass</Text>
        </Heading>
        <Box sx={confPassWrapperStyles}>
          <Box sx={confPassOverlayStyles}></Box>
          <ConfPass
            firstName={formattedFirstName}
            lastName={formattedLastName}
            number={number}
          />
        </Box>
        <Box sx={shareStyles}>
          <Text sx={shareTextStyles}>
            This page is your unique link you can use to invite your friends and
            colleagues:
          </Text>
          <pre sx={shareLinkStyles}>
            <code sx={codeStyles}>{uniqueUrl}</code>
            <Copy content={uniqueUrl} />
          </pre>
        </Box>
        <HubspotForm
          title={`Join ${name} by claiming your ticket!`}
          decorativeText={`6000+ people have already signed up!`}
        />
        <Box sx={formNoteStyles}>
          <Text>
            Find out more about <b>GatsbyConf</b> on&nbsp;
            <Link to="/" sx={homepageLinkStyles}>
              our homepage
            </Link>
            .
          </Text>
        </Box>
      </Grid>
    </Layout>
  );
};

export default RegistrantPage;

export const query = graphql`
  query($id: String) {
    registrant(id: { eq: $id }) {
      id
      submittedAt
      firstName
      lastName
      number
    }
  }
`;
